import HomeHeader from "./HomeHeader.js";
import './Home.css'
import Benefits from "./Benefits.js";
import HomeAboutUs from "./AboutUs.js";
import Join from "./HomeJoin.js";
import Meetings from "./Meetings.js";
import CheckUsOut from "./CheckUsOut.js";
function Home() {
    return (
        <>
            <div >
                <div className="home-table">
                    <HomeHeader />
                    <div className="home-row">
                        <div className="home-column large-section">
                            <HomeAboutUs />
                            <br />
                            <hr />
                            <Benefits />
                        </div>
                        <div className="home-column small-section">
                            <Join />
                            <hr />
                            <br />
                            <Meetings />
                            <br />
                            <hr />
                            <CheckUsOut />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;