import React from 'react';
import BrewNotes from '../../components/BrewNotes';

function BrewNote() {
    return (
        <>
        <div >
            <div className="master-table">
                <div className="master-row">
                    <div className="master-column master-section">
                        <h1>The Brew Note</h1>
                        <p>
                            Each month, the Brew Note newsletter is published and sent to members.  It 
                            outlines notes from the last meeting, general club information, and usually 
                            contains a tech topic.  Below are our past issues:
                        </p>
                        <BrewNotes />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default BrewNote;