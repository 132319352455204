import React, { useState, useEffect } from 'react';

const BrewNotes = () => {
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('Select All');

  // Fetch the JSON file when the component mounts
  useEffect(() => {
    fetch('/assets/BrewNotes/BrewNotes.json')
      .then((response) => response.json())
      .then((jsonData) => setData(jsonData))
      .catch((error) => console.error('Error fetching the JSON data:', error));
  }, []);

  // Get unique years from the data and sort them in reverse order
  const years = ['Select All', ...new Set(data.map(item => item.year))].sort((a, b) => b - a);

  // Filter and sort the data based on selected year and use month_num for sorting months
  const filteredData = selectedYear === 'Select All'
    ? data.sort((a, b) => {
        // First sort by year (reverse chronological)
        if (b.year !== a.year) return b.year - a.year;
        // Then sort by month_num (reverse chronological)
        return b.month_num - a.month_num;
      })
    : data.filter(item => item.year === selectedYear).sort((a, b) => {
        // Sort by month_num within the same year
        return b.month_num - a.month_num;
      });

  // Split data into three columns
  const chunkedData = (arr, size) => {
    return arr.reduce((chunks, item, index) => {
      if (index % size === 0) chunks.push([item]);
      else chunks[chunks.length - 1].push(item);
      return chunks;
    }, []);
  };

  const columns = chunkedData(filteredData, Math.ceil(filteredData.length / 3));

  return (
    <div className="link-list-container">
      {/* Dropdown to filter by year */}
      <select className="year-dropdown" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
        {years.map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>

      {/* Grid to display links in 3 columns */}
      <div className="link-columns">
        {columns.map((column, index) => (
          <div key={index} className="link-column">
            <ul className="link-list">
              {column.map(item => (
                <li key={item.filename} className="link-item">
                  <a href={`/assets/BrewNotes/${item.filename}`} target="_blank" rel="noopener noreferrer">
                    {item.month} {item.year}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrewNotes;
