function CheckUsOut() {
    return (
        <div >
            <center><h3>Check Us Out!</h3></center>
            <table>
                <tr>
                    <td>
                        <div>
                            <a href="https://www.facebook.com/groups/110584219030433/">
                                <img src="/assets/fb-art.png" alt="facebook"  className="social-img"/>
                            </a>
                        </div>
                    </td>
                    <td>
                        <div className="social-text">
                            Checkout our <a href="https://www.facebook.com/groups/110584219030433/">Facebook Group</a> and
                            connect with our local and extended group of homebrewers.  We try to keep it regularly updated with news of upcoming events, topics,
                            and general brewing information.
                        </div>
                        </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td>
                        <div>
                            <a href="https://twitter.com/stlbrews">
                                <img  src="/assets/X.png" alt="twitter"  className="social-img"/>
                            </a>
                        </div>
                    </td>
                    <td>
                        <div className="social-text">
                            Follow the <a href="https://twitter.com/stlbrews" >St. Louis Brews</a> on X.
                        </div>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td>
                        <div>
                            <a href="https://www.instagram.com/stlbrews">
                                <img  src="/assets/instagram.png" alt="twitter"  className="social-img"/>
                            </a>
                        </div>
                    </td>
                    <td>
                        <div className="social-text">
                            Follow the <a href="https://www.instagram.com/stlbrews" >St. Louis Brews</a> on Instagram.
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default CheckUsOut;