function HomeAboutUs() {
    return (
        <div >
            <center><h3>About Us</h3></center>
            <p>The St. Louis Brews Homebrew Club is the oldest and largest club in the St. Louis area. 
            Our focus is towards education of beer styles and homebrewing techniques. At each meeting, 
            we focus on a small number of styles. Members bring beers of that style, and we evaluate 
            and compare them, giving the brewers plenty of feedback on how to make their beers even better. 
            We also try to have a tech topic at each meeting where we discuss brewing equipment and procedures.
            </p>
            <p>
            We meet on the first Thursday of every month 
            at <a href="https://www.mknzbrewing.com/"><b>Mackenzie Brewing</b></a>, located at 932 Meramec Station Rd, Valley Park, 63088. 
            Directions can be  
            found <a  href="https://www.google.com/maps/place/Mackenzie+Brewing+Company/@38.5366586,-90.4975327,19.04z/data=!4m15!1m8!3m7!1s0x87d8d10ffdf4174f:0x9dc7be1cf156f58e!2s932+Meramec+Station+Rd,+Valley+Park,+MO+63088!3b1!8m2!3d38.5367268!4d-90.4973422!16s%2Fg%2F11bw3yk94w!3m5!1s0x87d8d10ffe421b99:0xb500eba328515e4a!8m2!3d38.5368775!4d-90.4973424!16s%2Fg%2F11f4qy7tx2?entry=ttu">HERE</a>
                                    . It is roughy located at I-44 and 141, and is in the same plaza as Sugarfire BBQ. 
            The meetings begin at 7:00pm and usually finish around 10:00pm. We do allow visitors to join our meetings 
            for a couple of times before we ask them to join.
            </p>
        </div>
    );
}

export default HomeAboutUs;