import React from 'react';
import { Link } from "react-router-dom";
import HHHCWinners from '../../components/HHHC_Winners';

function HHHC() {
    return (
        <div >
            <center>
                <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                            <center>
                                <img alt='HHHC Logo' width='200' src='/assets/hhhclogo.gif' />
                            </center>
                            <p>
                                The Happy Holiday Homebrew Competition is the St. Louis Brews annual homebrew competition. It is the largest in the
                                area and is among the largest in the state.  We usually set a cap of 650 entries and the cap is reached within
                                just a few days.  Because of the number of entries we receive, we modified the format of the competition so that 
                                HHHC is now beer-only, and meads and ciders have their own competition called "Mead Me In St. Louis (And Cider Me Too)".
                                Please refer to the Mead Me page for more information on that competition.  HHHC is a fully 
                                sanctioned AHA and BJCP event. 
                            </p>
                            <p>
                                Judging for HHHC is held the week before our annual HHHC banquet, which is typically held the second Saturday of 
                                December, but please refer to the <Link to="/calendar">Calendar</Link> page for more details.  Main judging is on the Saturday of the banquet,
                                and Friday, the day before.  Depending on the number of judges we can recruit, we also have judging sessions during
                                the week.
                            </p>
                            <p>
                                We are proud to be the final qualifying event for the <a href="https://www.masterhomebrewerprogram.com/">Master 
                                Homebrew Program Circuit of America</a>, and the final stop on 
                                the <a href="http://midwesthomebrewer.com/">Midwest Homebrewer of the Year</a> circuit.                                
                            </p>
                            <h3>Registration Site</h3>
                            <p>
                                The registration site for entering or signing up as a judge or steward is below.  Registration for the competition typically
                                opens about four weeks before the competition.  Please check the site for more details.
                                <br />
                                <center>
                                    <a href="https://stlbrews.brewingcompetitions.com/"><img alt='Register' width='200' src='/assets/register.jpg' /></a> 
                                </center>
                            </p>
                            <HHHCWinners />
                        </div>
                        
                    </div>
                </div>
            </center>
        </div>
    );
}

export default HHHC;