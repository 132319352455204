function HomeJoin() {
    return (
        <div >
            <center><h3>Join Our Club!</h3></center>
            <p>
                Our membership dues are $35 for a full calendar year. Please click below to pay with PayPal or credit card.
            </p>
            <center>
                <div>
                    <a 
                        href="https://www.paypal.com/donate/?hosted_button_id=8X9W25XKMMLG8" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <img 
                        src="/assets/PayPal.png"
                        alt="PayPal" 
                        style={{ width: '100px', height: 'auto' }} 
                        />
                    </a>
                </div>
            </center>

        </div>
    );
}

export default HomeJoin;