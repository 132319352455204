import { Link } from "react-router-dom";

function Meetings() {
    return (
        <div >
            <center><h3>Meetings</h3></center>
            <p>
                We meet on the first Thursday of every month at 7:00pm at Mackenzie Brewing. Once in a while we need to
                reschedule.  Please consult our <Link to='/calendar'>Calendar</Link> page for exact meeting dates.
            </p>
            <p>
                Our list of monthly brewing styles can be found <Link to='/club-info/styles'>Here</Link>.
            </p>
        </div>
    );
}

export default Meetings;