import React from 'react';
import './By-Laws.css';

function ByLaws() {
    return (
        <div>
            <div className="master-table">
                <div className="master-row">
                    <div className="master-column master-section">
                        <h1>Amended and Restated By-Laws</h1>
                        <p className="subtitle">The St. Louis Brews Home Brewing Club - March 2003</p>
                        <p>
                            Here are the collective By-Laws governing the social organization known as The St. Louis Brews Home
                            Brewing Club (the Club). These by-laws shall be made known to the membership of the Club and shall be
                            binding upon the officers and the members of the Club as stated within.
                        </p>
                        <p>
                        Changes to these By-Laws may only be made under the following conditions:
                        </p>
                        <ol>
                            <li>
                                Unanimous vote of the officers is required to bring a change, addition, or deletion to the membership
                                for general consideration and vote.
                            </li>
                            <li>
                                A vote of two-thirds (2/3) of members present in a formal meeting is required to pass the change,
                                addition, or deletion.
                            </li>
                            <li>
                                Changes, additions, or deletions voted by the membership may not be addressed again within one year
                                without a change in the circumstances which first prompted the application for change.
                            </li>
                        </ol>
                        <p>
                            Once established by the membership, a dated copy will be available in the "members only" section of the
                            website. A copy will be provided to any member upon request.
                        </p>
                        <h2>Status</h2>
                        <p>
                            The Club was incorporated as a General Not for Profit Corporation in the State of Missouri on June 13,
                            1994. The Club was approved for exemption from Federal Income tax by the IRS District Director in an IRS
                            Determination Letter dated September 20, 1995. The Club has a fiscal year-end of May 31 for purposes of
                            record keeping for any potential federal tax filings.
                        </p>

                        <h2>Purpose</h2>
                        <p>The purposes and objectives of the Club:</p>
                        <ol>
                            <li>
                                To increase membership knowledge of the art, science, and practice of home brewing through research,
                                sharing of experiences, and sharing of home brew products throughout the membership.
                            </li>
                            <li>
                                To increase membership knowledge of beer styles, brewing techniques, and history of beer-related
                                products through meeting presentations, educational seminars, and excursions.
                            </li>
                            <li>
                                To promote the hobby of home brewing through brewing demonstrations, publications, and participation
                                in competitions and related social events.
                            </li>
                            <li>
                                To broaden membership interest, knowledge, and participation in the brewing community by promoting
                                interaction with other similar organizations as well as commercial facilities.
                            </li>
                            <li>
                                To demonstrate to those outside the Club that home brewing is not about drunkenness, licentiousness,
                                folly, or irresponsibility through positive portrayal of the membership with regards to participation
                                in social gatherings, community activities, fundraisers, and other such activities.
                            </li>
                        </ol>
                        <h2>Membership</h2>
                        <p>
                            Any person who meets the following criteria will be considered as having membership of the Club:
                        </p>
                        <ol>
                            <li>Indicates a desire to participate in the activities sponsored or supported by the Club.</li>
                            <li>
                                Pays annual dues as determined by the membership in accordance with these By-Laws. The annual dues are
                                waived for other members of a household who meet the other membership requirements.
                            </li>
                            <li>Demonstrates responsible behavior and understanding of the Club objectives.</li>
                            <li>Is at least 21 years of age.</li>
                        </ol>
                        <p>
                            The Executive Committee may designate members in good standing to be Lifetime Members. Lifetime members
                            shall not pay annual dues or meeting fees. They are encouraged to bring beers to the meetings and share
                            their knowledge and expertise with other members.
                        </p>

                        <h2>Guests</h2>
                        <p>
                            A person who is at least 21 years of age may attend up to three (3) meetings as a guest in any 12-month
                            period. Guests will demonstrate responsible behavior.
                        </p>

                        <h2>Dues and Fees</h2>
                        <p>
                            Each member will be assessed an annual due. The officers are responsible for determining the proper amount
                            to support the Club activities. The majority vote of the members in any official meeting may change the
                            dues based on the executive committee's recommendation. The changed dues will become effective at the
                            beginning of the following calendar year.
                        </p>
                        <p>
                            Individuals joining the Club as new members after June of the year will be charged 50% of the annual due.
                            Individuals who had been members in the previous calendar year will pay a full year’s annual dues if their
                            membership is renewed after June. The annual dues are to be paid no later than the March meeting.
                        </p>
                        <p>
                            If annual dues are not paid by the March meeting, the membership will be revoked. All Club funds will be
                            maintained by the treasurer and are only to be used to support Club activities as determined by the
                            officers.
                        </p>
                        <p>
                            All Club funds will be maintained by the treasurer and are only to be used to support Club activities as 
                            determined by the officers.
                        </p>
                        <p>
                            The Club shall not borrow funds from financial institutions or individuals.
                        </p>
                        <h2>Officers</h2>
                        <p>
                            The Club will have five officers. Each officer will be a member in good standing. Each officer shall have
                            equal powers to represent the Club and the membership in the conduct of business and making contracts with
                            outside agencies and individuals as defined by their individual job descriptions.
                        </p>
                        <p>
                            All officers shall be elected at the January Club meeting by a simple majority vote of the members in
                            attendance. All terms of office will be one year. Officers may not serve more than two consecutive terms.
                        </p>
                        <h3>PRESIDENT</h3>
                        <ul>
                            <li>Presides over meetings, including determining the agenda, recognizing individuals who wish to speak and/or 
                                conduct business, facilitates and opens and closes meetings.</li>
                            <li>The sole officer able to sign formal contracts and other legal documents as a representative of the Club 
                                and its membership. This does not include a sole authority to obligate funds.</li>
                            <li> Coordinates with other officers and members to ensure that the goals and objectives are being realized, 
                                including the discipline of member conduct.</li>
                        </ul>
                        <h3>VICE PRESIDENT</h3>
                        <ul>
                            <li>Presides over meetings in the absence of the president.</li>
                            <li>Records the minutes at meetings and provides them to the Club secretary for publication.</li>
                            <li>Establishes and organizes technical contents of the membership meetings.</li>
                            <li>Assists the president with development and implementation of Club goals and objectives.</li>
                        </ul>
                        <h3>SECRETARY</h3>
                        <ul>
                            <li>Maintains records of membership.</li>
                            <li>Distributes membership documents.</li>
                            <li>Maintains records in the executive committee meetings.</li>
                            <li>Prepares and distributes the monthly Club newsletter.</li>
                            <li>Maintains calendar of events for regional and national homebrew competitions and informs the membership about such events.</li>
                            <li>Maintains official copy of the by-laws.</li>
                        </ul>
                        <h3>TREASURER</h3>
                        <ul>
                            <li>Maintains all funds and financia l records associated with the Club, including all forms and sources of income 
                                and all expenses associated with the conduct of business of the Club for the good of the Club and its membership.</li>
                            <li>Has the signature authority of the Club checking and savings accounts, if any.</li>
                            <li>Disburses Club funds for approved and routine expenditures related to the operation of the Club.</li>
                            <li>Collects annual dues and the meeting dues and other fees.</li>
                            <li>Prepares and submits all forms required for financial accountability to federal, state and local authorities.</li>
                            <li>Also, maintains all licenses, authorizations and certifications required to maintain the Club legal status as a not for profit organization.</li>
                            <li>Maintains a roster of active members, meeting attendance and beers brought to meetings.</li>
                            <li>Obtains approval of the president and one other officer for an expenditure of Club funds in excess of $250.</li>
                        </ul>
                        <h3>BREWMEISTER</h3>
                        <ul>
                            <li>Provides the records of beers contributed to the meetings to the treasurer.</li>
                            <li>Provides commercial examples of the month’s style(s) when possible and organizes the member contributions for presentation.</li>
                            <li>Provides snacks for the meetings.</li>
                            <li>Organizes a volunteer staff to present the food and beer in the meetings.</li>
                            <li>Maintains the equipment needed to present the beers and food in the meetings including glasses and pitchers.</li>
                        </ul>
                        <h2>APPOINTED OFFICERS</h2>
                        <p>
                            The executive committee may appoint other officers without a voting right in the executive committee meetings. 
                            These officers may include but are not limited to WEBMEISTER, EDUCATION DIRECTOR and MEMBER- AT-LARGE. 
                            The duties of these appointed officers are separately defined by the executive committee. The term of these 
                            officers coincides with the term of the executive board that appointed them. Re-appointment by the subsequent 
                            executive committees is allowed.
                        </p>
                        <h2>EXECUTIVE COMMITTEE</h2>
                        <p>
                            The Club shall be led and governed by the Executive Committee. The Executive committee consists of the five 
                            elected officers and the appointed officers. Appointed officers do not have a voting right. Additional 
                            participants may be invited to the committee meetings as deemed necessary by the committee. These participants 
                            will serve as specialists or consultants without a voting right.
                        </p>
                        <p>
                            The Executive Committee shall meet semi-annually or more often if necessary to ensure the Club goals and 
                            objectives are being met. A reasonable allocation of the Club funds shall be appropriated for these meetings.
                        </p>
                        <p>
                            The Executive Committee may establish awards or special recognition for non-officer members who have made 
                            significant contributions to the Club, including but not limited to “Homebrewer of the Year” and “Lifetime Member” 
                            awards.
                        </p>
                        <p>
                            All members of the Executive Committee must be members in good standing with the Club. By majority vote, the 
                            executive committee may, based on severe breach of the Club member conduct rule, suspend an officer for a 
                            remainder of the term and appoint a replacement for the position.
                        </p>
                        <p>
                            If an officer can not complete the term of office, the Executive Committee will appoint a replacement.  
                        </p>
                        <h2>DISCIPLINARY ACTION</h2>
                        <p>Any member or officer may be disciplined for reasons including but not limited to the following:</p>
                        <ul>
                            <li>Misrepresentation of the Club in the negotiations of contracts, promise of payment of moneys,
                            materials in kind or services rendered, promise of participation or sponsorship of an outside activity, 
                            or in the portrayal of the goals and objectives of the Club.</li>
                            <li>Failure to meet the requirements of membership.</li>
                            <li>Theft, misuse or abuse of moneys, materials of properties belonging to the Club.</li>
                            <li>Unruly, antisocial or criminal behavior while participating in a meeting or social conducted, sponsored or 
                                condoned by the Club or while representing the Club and its officers or membership in any capacity with an 
                                outside organization or individual. This may be extended to include any behavior which may 
                                cast an unfavorable light on the Club in the eyes of any outside agency or individual which stands the 
                                possibility of negatively affecting the Club’s ability to obtain new members, negotiate contracts, and 
                                secure sites for meetings, or events.</li>
                        </ul>
                        <p>
                            Disciplinary action may take any form of the following:
                        </p>
                        <ul>
                            <li>Written warning to the offending member./li></li>
                            <li>Revocation of the membership. The prorated annual dues will be forfeited./li></li>
                            <li>Criminal action during a Club event or activity will lead to immediate expulsion from the Club./li></li>
                        </ul>
                        <p>
                            The disciplinary action will be executed by the president or other Club officer designated by him/her. 
                            The officers’ simple majority vote will initiate the disciplinary action.
                        </p>
                        <h2>ELECTIONS AND VOTING PRACTICES</h2>
                        <p>
                            The officers will be elected annually in the January meeting. A simple majority vote from the attending 
                            members is required for each position. Only members in good standing can vote.
                        </p>
                        <p>
                            The nomination for the offices may be made in the November, December or January meetings. Except for the 
                            2/3 majority required to revise the by-laws, all member issues requiring voting will require simple majority 
                            vote to pass. The quorum is the attending membership in the respective meeting.
                        </p>
                        <h2>ACTIVITIES MEETINGS</h2>
                        <p>
                            All official business will be conducted during a membership meeting. Meetings may be held in
                            conjunction with events.
                        </p>
                        <ul>
                            <li>The president will schedule a regular membership meeting monthly. These meetings will be scheduled 
                                at least 30 days in advance and all members will be notified through any appropriate means.</li>
                            <li>Any officer may call a meeting by notifying the membership through mailings, phone calls or other 
                                appropriate means.</li>
                            <li>Any member wishing to conduct business at a meeting shall notify the President at least 72 hours 
                                in advance to ensure inclusion in the agenda. The President may allow discussion of items not on the agenda.</li>
                            <li>The meeting is the forum to conduct the Club’s normal business. The beers presented will be evaluated 
                                and feedback to the brewers is provided. Periodical financial reports will be delivered by the treasurer 
                                as requested by the executive committee. The events will be announced and planned. Old and new business 
                                will be processed. Possible technical topics will be offered.</li>
                        </ul>
                        <h2>EVENTS</h2>
                        <p>
                            Events involve officially sanctioned activities of the Club. The events are designed to promote the goals and 
                            objectives of the Club in a public or private forum. With the approval of the Executive Committee, Club events 
                            may be open to participation of other homebrewing clubs or the general public. Events do not require a quorum. 
                            Events may include competitions, practical demonstrations, classes, open forums, pub crawls, tastings, tours, 
                            or any other activities condoned by the Club.
                        </p>
                        <p>
                            For each event, the President will appoint a coordinator to organize the event details and budget and act 
                            as the master of ceremonies in the event.
                        </p>
                        <h2>EXPENSES</h2>
                        <p>
                            The membership expects to incur expenses for the normal operation of the Club as a 
                            whole and for the conduct of events such as Club-sponsored picnics and competitions 
                            above said normal operation. Additional expenses may be incurred for such events. Authorizations 
                            required prior to disbursement of Club funds are described below.
                        </p>
                        <ul>
                            <li>Administrative expenses are associated with the normal conduct of meetings and the normal 
                                administration of the Club. They may include office supplies, Internet service fees, copies, 
                                materials for membership activities and other related supplies. As these are normal expenses 
                                the Treasurer will pay for them as necessary from the Club’s regular funds.</li>
                            <li>Event expenses are associated with hosting or participation an event. Event expenses will be 
                                coordinated between the officers and the event coordinator and approved by the officers.</li>
                            <li>Gifts or donations to other organizations. Gifts or donations of $250 or le ss must be approved 
                                by a simple majority vote of the Executive Committee. Gifts or donations of more than $250 must 
                                be approved by a simple majority vote of the membership present at a meeting.</li>
                            <li>Capital expenses are incurred by the outright purchase of hard assets. All capital expenses will 
                                be approved by majority vote of the Executive Committee. Expenses in excess of $250 must be approved 
                                by a simple majority vote of the membership present at a meeting.</li>
                            <li>Promotional expenses are incurred in the promotion of the organization. They may include production 
                                of shirts, mugs, buttons, stickers, patches etc. All promotional expenses will be approved by majority 
                                vote of the Executive Committee. Promotional expenses in excess of $250 must be approved by a simple 
                                majority vote of the membership present at a meeting.</li>
                        </ul>


                        <h2>INSURANCE</h2>
                            <p>
                                At the recommendation of the Executive Committee and with the approval of a majority of the membership 
                                present at a meeting, the Club may obtain insurance.
                            </p>
                        <h2>CONTRACTS AND AGREEMENTS</h2>
                        <p>
                            At times, it may be necessary for the Club to enter into contracts and agreements 
                            with outside agencies. This may involve procuring a site for activities, arranging for 
                            supplies, materials or services from outside agencies. Given that this may involve 
                            legalities, the following rules apply:
                        </p>
                        <ul>
                            <li>With the approval of the majority of the Executive Committee and subject to the 
                                required authorizations of expenditures described elsewhere in these By-Laws, 
                                an officer may enter the Club into an obligation that would legally bind the Club, 
                                its officers, or its membership to providing or receiving goods or services for 
                                any reason. Anyone representing himself or herself as a legal representative 
                                of the Club, its officers, or its members without officers’ consent will be 
                                considered guilty of misrepresentation and may face a legal action.</li>
                            <li>With regards to the obligation of the funds belonging to the Club, the funding 
                                must be approved as stated elsewhere in these By-Laws.</li>
                            <li>Should any misrepresentation in the making of contracts or negotiations come to 
                                light, the individual guilty of misrepresentation will become solely and individually 
                                liable to make good on contract or obligation.</li>
                            <li>Should any officer enter into a contract or obligation in good faith that the 
                                membership later reneges on, the Club will make good on the contract or obligation as provided in said contract.</li>
                        </ul>
                        <h2>DISSOLUTION</h2>
                        <p>
                            There may come a time when the existence of the Club is no longer desirable or 
                            tenable. Should this be the case the provisions laid out here govern this determination, 
                            the responsibilities of the officers and members and the liquidation and distribution of the assets.
                        </p>
                        <ul>
                            <li>Any officer or member may raise the issue of the dissolution at any meeting. At this time 
                                the entire membership on record will be polled in writing or electronically. The polling 
                                may take up to 30 days and two thirds vote is required for the dissolution approval.</li>
                            <li>Once the membership votes to dissolve, all future meetings, events will be canceled. The 
                                officers must take steps to resolve any conflicts that may arise from previous commitments.</li>
                            <li>Any capital assets will be sold by the most expedient means possible without threatening 
                                the fair market value of the assets.</li>
                            <li>Any accounts payable will immediately be resolved. Should the accounts payable exceed the 
                                assets available, the membership and officers will be equally billed for the difference. 
                                Once each obligation is settles the associated bank account(s) will be closed.</li>
                            <li>Any remaining funds will be donated to a local charity designated by the Executive Committee 
                                based on membership advice obtained when polling for the dissolution.</li>
                            </ul>








                    </div>
                </div>
            </div>
        </div>
        );
}

export default ByLaws;
