import React from 'react';
import { Link } from "react-router-dom";

function AboutUs() {
    return (
        <div >
                <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                            <h1>About Us</h1>
                            <img
                                alt="Typical Brews Meeting"
                                style={{ width: "100%", maxWidth: "500px", height: "auto" }}
                                src="/assets/meeting-last.jpg"
                                />
                            <p >
                                A typical St. Louis Brews meeting.
                            </p>
                            <br />
                            <h2>When &amp; Where We Meet</h2>
                            <p>
                                We meet on the first Thursday of every month at <a href="https://www.mknzbrewing.com/"><b>Mackenzie Brewing
                                    </b></a>, located at 932 Meramec Station Rd, Valley Park, 63088. Directions can be found&nbsp;
                                <a
                                    href="https://www.google.com/maps/place/Mackenzie+Brewing+Company/@38.5366586,-90.4975327,19.04z/data=!4m15!1m8!3m7!1s0x87d8d10ffdf4174f:0x9dc7be1cf156f58e!2s932+Meramec+Station+Rd,+Valley+Park,+MO+63088!3b1!8m2!3d38.5367268!4d-90.4973422!16s%2Fg%2F11bw3yk94w!3m5!1s0x87d8d10ffe421b99:0xb500eba328515e4a!8m2!3d38.5368775!4d-90.4973424!16s%2Fg%2F11f4qy7tx2?entry=ttu">HERE</a>.
                                It is roughy located at I-44 and 141, and is in the same plaza as Sugarfire BBQ.
                                The meetings begin at 7:00pm and usually
                                finish around 10:00pm. We do allow visitors to join our meetings for a couple of times before we ask them to
                                join.
                            </p>
                            <p>
                                <b>The St. Louis Brews is a club dedicated to the brewing, evaluation, and responsible consumption of homebrewed
                                    beers.</b>
                            </p>
                             <h2>What We Do</h2>
                            <p>
                                We focus on a particular style, or styles for a given meeting. Please see the monthly meeting schedule on the
                                &nbsp;<Link to='/club-info/styles'>styles</Link> page.&nbsp;
                                Our members bring beers of that style, and we evaluate and compare against commercial examples. In most cases
                                our members&#39; homebrewed beers are better than the commercial examples!
                                If you would like to bring a beer for evaluation at a meeting, we normally ask you to bring at least (6) 12oz.
                                bottles or an equivalent amount of draft beer from a keg (1 growler).&nbsp;

                                Please let our <Link to='/contact-brewmeister'>Brewmeister</Link> know if you are bringing beer so we know
                                how much to expect.
                            </p>

                            <h2>Is There a Cost?</h2>
                            <p>
                                Membership is currently $35.00 per calendar year. If are a new member and join after July 1, the
                                membership fee is $17.50. 
                            </p>
                            <p>
                                When you become a member you receive a monthly newsletter,
                                invitations to our two picnics, our National Homebrew Day celebration, and our end of year banquet, not to
                                mention special events that come along such as brewery tours,
                                meet &amp; greets with brewing illuminati, beer tastings, etc.  Want to give us a test drive before
                                joining?&nbsp;
                                Come as a guest a few times.&nbsp;

                                <b> Membership in the St. Louis Brews and
                                    attendance at our meetings is restricted to those 21 years of age and older.</b>
                            </p>
                            <h2>Contact Us</h2>
                            <p>
                                <Link to='/contact-president'>Cory Kaufman - President</Link><br/>
                                <Link to='/contact-vicepresident'>Kent Robertson - Vice President</Link><br/>
                                <Link to='/contact-secretary'>Matt Schaller - Secretary</Link><br/>
                                <Link to='/contact-treasurer'>Mike Green - Treasurer</Link><br/>
                                <Link to='/contact-brewmeister'>Mark Kunzelman - Braumeister</Link><br/>
                                <Link to='/contact-jockeyboxes'>Ted O'Neill - Jockey Boxes</Link><br/>
                                <Link to='/contact-webmeister'>Mike Walters, Willie Nordmann - Webmeister</Link><br/>
                                <Link to='/contact-calendar'>Mike Walters - Calendar</Link><br/>
                                <Link to='/contact-bjcpeducation'>Jeff Muse - BJCP Classes</Link><br/>
                                <Link to='/contact'>Officers</Link>
                            </p>
                            <h4>
                                <a href="http://www.homebrewersassociation.org/pages/grow-your-business/banner-ads/st-louis-brews">American
                                    Homebrewers Association
                                    Signup</a>
                            </h4>
                            <p>
                                Interested in joining the AHA?&nbsp; Start or renew your membership through the
                                link below and the club gets a $5 bonus! Its a great way to help your club out
                                while helping yourself with a great AHA membertship.
                            </p>
                            <p>
                                <a href="http://www.homebrewersassociation.org/pages/grow-your-business/banner-ads/st-louis-brews">
                                <img
                                        
                                        src="/assets/AHA180x150.gif" alt="AHA" /></a>
                            </p>

                            <br />
                            <p >
                                We all have a great time. Hope to see you there!
                            </p>

                            <div>
                            <img
  alt="bottle animation"
  style={{ width: "50px", height: "auto" }}
  src="/assets/bottle1.gif"
/>
                            </div>
                        </div>
                    </div>
                </div>
       </div>
    );
}

export default AboutUs;