import React from 'react';
import { Link } from 'react-router-dom';

function Supporters() {
    return (
        <div >
            <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                            <p>
                                The following establishments support the St. Louis Brews. In addition to the
                                many things they do for the club in general, they do the following for our
                                members as well. Please try to frequent these establishments as they do help out
                                our club. By showing your club card, you not only receive a discount, but you
                                let them know that we are supporting their businesses as well.&nbsp; If you are
                                a business that is interested in expanding your customer base and increasing
                                your exposure to dedicated beer enthusiasts, please contact 
                                the <Link to='/contact-president'>club president</Link> and we would be happy
                                to consider adding you to our list of Friends.
                            </p>
                            <table border="0" width="620" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td colspan="1" width="600" valign="top" align="left">
                                        <h4>Brewpubs/Microbreweries</h4>
                                        <p>
                                            <a href="http://www.perennialbeer.com/">Perennial Artisan Ales</a><br/>
                                            8125 South Michigan<br/>
                                            St. Louis, MO
                                        </p>
                                        <ul>
                                            <li>10% off all purchases </li>
                                        </ul>

                                        <p>
                                            <a href="http://schlafly.com/tap-room/">St. Louis Brewery and Tap Room</a><br/>
                                            2100 Locust<br/>
                                            St. Louis, MO <br/>
                                            314-241-BEER x1
                                        </p>
                                        <ul>
                                            <li>Employee prices on kegs</li>
                                            <li>10% off all merchandise purchases</li>
                                        </ul>

                                        <p>
                                            <a href="https://www.stubborngermanbrewing.com/">Stubborn German Brewing Company</a><br/>
                                            119 South Main St.<br/>
                                            Waterloo, IL 62298<br/>
                                            314-966-2739
                                        </p>
                                        <ul>
                                            <li>$1.00 off a sampler flight</li>
                                        </ul>

                                        <h4>Beer Bars &amp; Liquor Stores</h4>
                                        <p>
                                            <a href="http://www.starrsmarket.com/">Starr's Market/Bar Oliver</a><br/>
                                                1135 South Big Bend Boulevard<br/>
                                                St. Louis, MO<br/>
                                                314-781-2345
                                        </p>
                                        <ul>
                                            <li>5% off all import &amp; craft beers</li>
                                        </ul>

                                    </td>
                                    <td colspan="1" width="20">

                                    </td>
                                    <td colspan="1" width="300" valign="top" align="left">
                                        <h4>Local Homebrew Supply Stored</h4>
                                        <p>
                                            <a href="http://momalt.com/">Missouri Malt Supply</a><br/>
                                                2275 Cassens Drive # 126<br />
                                            Fenton, MO 63026<br />
                                                (314) 779-MALT
                                        </p>
                                        <ul>
                                            <li>10% discount (some exclusions)</li>
                                        </ul>

                                    
                                        <h4>National Organizations</h4>
                                    
                                        <p>
                                            Get your AHA membership through this link, and it benefits our club!<br />
                                            <a href="http://www.homebrewersassociation.org/pages/grow-your-business/banner-ads/st-louis-brews"><img src="/assets/AHA180x150.gif" alt="AHA" /></a>
                                        </p>
                                    
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

        </div>
    );
}

export default Supporters;