import React from 'react';
import './styles.css'; // Import the CSS file

const BeerStyles = () => {
  return (
    <>
      <div className="master-table">
        <div className="master-row">
          <div className="master-column master-section">
            <p>
              Below are the official styles for each month.  During each monthly meeting, we will evaluate
              all of the beers brought.  Of course, all styles are welcome, regardless of whether they are
              one of the styles for the month.   However, the 
              internal competition for the best beer of the night is reserved for beers that conform to one
              of the listed styles.
            </p>

            <div className="month-section">
              <h2 className="month-title">January</h2>

              <div className="styles-group">
                <h3 className="group-title">Strong European Beer</h3>
                <ul className="styles-list">
                  <li>9C - Baltic Porter</li>
                </ul>
              </div>

              <div className="styles-group">
                <h3 className="group-title">British Strong Ale</h3>
                <ul className="styles-list two-column-list">
                  <li>17A - British Strong Ale</li>
                  <li>17B - Old Ale</li>
                  <li>17C - Wee Heavy</li>
                  <li>17D - English Barleywine</li>
                </ul>
              </div>
              
              <div className="styles-group">
                <h3 className="group-title">Strong American Ale</h3>
                <ul className="styles-list two-column-list">
                  <li>22A - Double IPA</li>
                  <li>22B - Strong American Ale</li>
                  <li>22C - American Barleywine</li>
                  <li>22D - Wheatwine</li>
                  <li>20C - Imperial Stout</li>
                </ul>
              </div>
            </div>
            

            <div className="month-section">
              <h2 className="month-title">February</h2>
              <div className="styles-group">
                <h3 className="group-title">Standard Cider and Perry</h3>
                <ul className="styles-list two-column-list"> 
                  <li>C1A - New World Cider </li>
                  <li>C1B - English Cider </li>
                  <li>C1C - French Cider </li>
                  <li>C1D - New World Perry </li>
                  <li>C1E - Traditional Cider Specialty Cider and Perry </li>
                  <li>C2A - New England Cider </li>
                  <li>C2B - Cider with Other Fruit </li>
                  <li>C2C - Applewine </li>
                  <li>C2D - Ice Cider </li>
                  <li>C2E - Cider with Herbs/Spices </li>
                  <li>C2F - Specialty Cider/Perry Traditional </li>
                </ul>
              </div>
              
              <div className="styles-group">
                <h3 className="group-title">Mead </h3>
                <ul className="styles-list two-column-list">
                  <li>M1A - Dry Mead </li>
                  <li>M1B - Semi-Sweet Mead </li>
                  <li>M1C - Sweet Mead Fruit Mead </li>
                  <li>M2A - Cyser M2B - Pyment </li>
                  <li>M2C - Berry Mead </li>
                  <li>M2D - Stone Fruit Mead </li>
                  <li>M2E - Melomel Spiced Mead </li>
                  <li>M3A - Fruit and Spice Mead </li>
                  <li>M3B - Spice, Herb, or Vegetable Mead Specialty Mead </li>
                  <li>M4A - Braggot M4B - Historical Mead </li>
                  <li>M4C - Experimental Mead</li>
                </ul>
              </div>
            </div>
      
      
            <div className="month-section">
              <h2 className="month-title">March</h2>

              <div className="styles-group">
                <h3 className="group-title">Scottish Ales </h3>
                <ul className="styles-list two-column-list"> 
                  <li>14A - Scottish Light </li>
                  <li>14B - Scottish Heavy </li>
                  <li>14C - Scottish Export Irish Beer </li>
                </ul>
              </div>
              
              <div className="styles-group">
                <h3 className="group-title">Irish Beer</h3>
                <ul className="styles-list two-column-list"> 
                  <li>15A - Irish Red Ale </li>
                  <li>15B - Irish Stout </li>
                  <li>15C - Irish Extra Stout Dark </li>
                </ul>
              </div>
              
              <div className="styles-group">
                <h3 className="group-title">British Beer</h3>
                <ul className="styles-list two-column-list"> 
                  <li>16C - Tropical Stout </li>
                  <li>16D - Foreign Extra Stout </li>
                </ul>
              </div>
              
              <div className="styles-group">
                <h3 className="group-title">American Porter and Stout</h3>
                <ul className="styles-list two-column-list">
                  <li>20A - American Porter </li>
                  <li>20B - American Stout </li>
                </ul>
              </div>
            </div>

            <div className="month-section">
              <h2 className="month-title">April</h2>
              <div className="styles-group">
                <h3 className="group-title">Brown British Beer  </h3>
                <ul className="styles-list two-column-list"> 
                  <li>13A - Dark Mild </li>
                  <li>13B - British Brown Ale </li>
                  <li>13C - English Porter  </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Dark British Beer </h3>
                <ul className="styles-list two-column-list"> 

                  <li>16A - Sweet Stout </li>
                  <li>16B - Oatmeal Stout </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Historical Beer </h3>
                <ul className="styles-list two-column-list"> 
                  <li>Pre-Prohibition Porter </li>
                  <li>London Brown Ale </li>
                </ul>
              </div>
            </div>

            <div className="month-section">
              <h2 className="month-title">May  </h2>
              <div className="styles-group">
                <h3 className="group-title">British Bitter </h3>
                <ul className="styles-list two-column-list"> 
                  <li>11A - Ordinary Bitter </li>
                  <li>11B - Best Bitter </li>
                  <li>11C - Strong Bitter Pale </li>
                  </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Commonwealth Beer</h3>
                <ul className="styles-list two-column-list">  
                  <li>12A - British Golden Ale </li>
                  <li>12B - Australian Sparkling Ale </li>
                  <li>12C - English IPA  </li>
                  </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Pale American Ale</h3>
                <ul className="styles-list two-column-list">  
                  <li>18A - Blonde Ale </li>
                  <li>18B - American Pale Ale </li>
                  </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">American Amber/Brown Ale </h3>
                <ul className="styles-list two-column-list"> 
                  <li>19A - American Amber Ale </li>
                  <li>19B - California Common </li>
                  <li>19C - American Brown Ale </li>
                  </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">IPA </h3>
                <ul className="styles-list two-column-list"> 
                  <li>21A - American IPA </li>
                  <li>21B - Specialty IPA </li>
                </ul>
              </div>
            </div>

            <div className="month-section">
              <h2 className="month-title">June </h2>
              <div className="styles-group">
                <h3 className="group-title">Standard American Beer  </h3>
                <ul className="styles-list two-column-list"> 
                  <li>1A - American Light Lager </li>
                  <li>1B - American Lager </li>
                  <li>1C - Cream Ale </li>
                  <li>1D - American Wheat Beer </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Malty European Lager  </h3>
                <ul className="styles-list two-column-list"> 
                  <li>4A - Munich Helles </li>
                  <li>4B - Festbier </li>
                  <li>4C - Helles Bock </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Amber Bitter European Beer  </h3>
                <ul className="styles-list two-column-list"> 
                  <li>7A - Vienna Lager </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Strong European Beer  </h3>
                <ul className="styles-list two-column-list"> 
                  <li>9B - Eisbock </li>
                </ul>
              </div>
            </div>

            <div className="month-section">
              <h2 className="month-title">July </h2>
              <div className="styles-group">
                <h3 className="group-title">International Lagers </h3>
                <ul className="styles-list two-column-list"> 
                  <li>2A - International Pale Lager </li>
                  <li>2B - International Amber Lager </li>
                  <li>2C - International Dark Lager </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Czech Lager </h3>
                <ul className="styles-list two-column-list"> 
                  <li>3A - Czech Pale Lager </li>
                  <li>3B - Czech Premium Pale Lager </li>
                  <li>3C - Czech Amber Lager </li>
                  <li>3D - Czech Dark Lager Pale </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Bitter European Beer </h3>
                <ul className="styles-list two-column-list"> 
                  <li>5A - German Leichtbier </li>
                  <li>5B - Kolsch </li>
                  <li>5C - German Helles Exportbier </li>
                  <li>5D - German Pils </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Historical Beer </h3>
                <ul className="styles-list two-column-list"> 
                  <li>Pre-prohibition Lager </li>
                  <li>Kentucky Common</li>
                </ul>
              </div>
            </div>

            <div className="month-section">
              <h2 className="month-title">August</h2>
              <div className="styles-group">
                <h3 className="group-title">German Wheat Beer </h3>
                <ul className="styles-list two-column-list"> 
                  <li>10A - Weissbier </li>
                  <li>10B - Dunkles Weissbier </li>
                  <li>10C - Weizenbock </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Belgian Ales </h3>
                <ul className="styles-list two-column-list"> 
                  <li>24A - Witbier </li>
                  <li>24C - Biere de Garde </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Strong Belgian Ale </h3>
                <ul className="styles-list two-column-list">  
                  <li>25B - Saison </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Historical Beer </h3>
                <ul className="styles-list two-column-list"> 
                  <li>Roggenbier </li>
                  </ul>
              </div>
            </div>

            <div className="month-section">
              <h2 className="month-title">September </h2>
              <div className="styles-group">
                <h3 className="group-title">Belgian Ale </h3>
                <ul className="styles-list two-column-list"> 
                  <li>24B - Belgian Pale Ale </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Strong Belgian Ale  </h3>
                <ul className="styles-list two-column-list"> 
                  <li>25A - Belgian Blonde Ale </li>
                  <li>25C - Belgian Golden Strong Ale </li>
                </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Trappist Ale </h3>
                <ul className="styles-list two-column-list"> 
                  <li>26A - Trappist Single </li>
                  <li>26B - Belgian Dubbel </li>
                  <li>26C - Belgian Tripel </li>
                  <li>26D - Belgian Dark Strong </li>
                </ul>
              </div>
            </div>

            <div className="month-section">
              <h2 className="month-title">October </h2>
              <div className="styles-group">
                <h3 className="group-title">Amber Malty European Lager </h3>
                <ul className="styles-list two-column-list"> 
                <li>6A - Marzen </li>
                <li>6B - Rauchbier </li>
                <li>6C - Dunkles Bock </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Amber Bitter European Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>7B - Altbier </li>
                <li>7C - Kellerbier Dark </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">European Lager </h3>
                <ul className="styles-list two-column-list"> 
                <li>8A - Munich Dunkel </li>
                <li>8B - Schwarzbier </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Strong European Lager </h3>
                <ul className="styles-list two-column-list"> 
                <li>9A- Doppelbock </li>
                </ul>
              </div>
            </div>

          <div className="month-section">
              <h2 className="month-title">November </h2>
              <div className="styles-group">
                <h3 className="group-title">European Sour Ale </h3>
                <ul className="styles-list two-column-list"> 
                <li>23A - Berliner Weiss </li>
                <li>23B - Flanders Red Ale </li>
                <li>23C - Oud Bruin </li>
                <li>23D - Lambic </li>
                <li>23E - Gueuze </li>
                <li>23F - Fruit Lambic </li> 
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">American Wild Ale </h3>
                <ul className="styles-list two-column-list"> 
                <li>28A - Brett Beer </li>
                <li>28B - Mixed-Fermentation Sour Beer </li>
                <li>28C - Wild Specialty Beer </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Fruit Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>29A - Fruit Beer </li>
                <li>29B - Fruit and Spice Beer </li>
                <li>29C - Specialty Fruit Beer </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Historical Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>Gose </li>
                <li>Lichtenhainer </li>
                </ul>
              </div>
            </div>

          <div className="month-section">
              <h2 className="month-title">December </h2>
              <div className="styles-group">
                <h3 className="group-title">Spiced Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>30A - Spice/Herb/Vegetable Beer</li> 
                <li>30B - Autumn Seasonal Beer </li>
                <li>30C - Winter Seasonal Beer </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Alternative Fermentables Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>31A - Alternative Grain Beer </li>
                <li>31B - Alternative Sugar Beer </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Smoked Beer</h3>
                <ul className="styles-list two-column-list"> 
                <li>32A - Classic Style Smoked Beer </li>
                <li>32B - Specialty Smoked Beer </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Wood Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>33A - Wood-Aged Beer </li>
                <li>33B - Specialty Wood-Aged Beer </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Specialty Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>34A - Clone Beer </li>
                <li>34B - Mixed-Style Beer </li>
                <li>34C - Experimental Beer </li>
              </ul>
              </div>
              <div className="styles-group">
                <h3 className="group-title">Historical Beer </h3>
                <ul className="styles-list two-column-list"> 
                <li>Piwo Grodziske </li>
                <li>Sahti </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeerStyles;
