import React from 'react';
import { Link } from "react-router-dom";
import MeadMeWinners from '../../components/MeadMe_Winners';

function MeadMe() {
    return (
        <div >
            <center>
                <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                            <center>
                                <img alt='MeadMe Logo' width='200' src='/assets/MeadMe.jpg' />
                            </center>
                            <p>
                                HHHC got so big that we decided to spin off meads and ciders into their own competition called
                                Mead Me In St. Louis (And Cider Me Too).  Or just "Mead Me" for short.  Not only is it a way to judge more
                                entries than HHHC would allow, but it lets cider and mead judges judge those beverages without the distraction
                                of also needing to judge beer.
                            </p>
                            <p>
                                The St. Louis area is now home to 21 certified mead judges and 9 certified cider judges.  This large of A
                                judging pool allows us to have two certified judges on nearly every entry, giving great feedback to all
                                entrants.  And of course, Mead Me is a fully 
                                sanctioned AHA and BJCP event.
                            </p>
                            <p>
                                The Mead Me competition has great support from the National Honey Board which generously donates prizes 
                                for our competition.  Last year, category winners each received a 5-lb container of honey.  In addition to
                                a Mead/Cider Best of Show round, we also have a Mead-only BOS, with the winner receiving up to 20 lb of honey.
                            </p>
                            <p>
                                The Mead Me competition takes place in September, which coincides with National Honey Month.  Last year, it was held 
                                the weekend after Labor Day, but a date has not yet been firmed up.  Please check the <Link to="/calendar">Calendar</Link> page for more details.  Main judging is on the Saturday of the banquet,
                                for more details.
                            </p>
                            <h3>Registration Site</h3>
                            <p>
                                The registration site for entering or signing up as a judge or steward is below.  Registration for the competition typically
                                opens about four weeks before the competition.  Please check the site for more details.
                                <br />
                                <center>
                                    <a href="https://stlbrews.brewingcompetitions.com/"><img alt='Register' width='200' src='/assets/register.jpg' /></a> 
                                </center>
                            </p>
                            <MeadMeWinners />
                        </div>
                    </div>
                </div>
            </center>
        </div>
    );
}

export default MeadMe;