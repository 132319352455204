import React from 'react';
import { Link } from "react-router-dom";

function BIL() {
    return (
        <div >
            <center>
                <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                            <center>
                                <img alt='Brew In The Lou Logo' width='200' src='/assets/BIL.png' />
                            </center>
                            <p>
                                The Brew In The Lou homebrew competition is sponsored by the St. Louis
                                Homebrew Guild, and is associated with the Brew In The Lou beer festival that 
                                benefits the Lutheran Elementary School Association (LESA).  The festival is
                                usually held in October at Francis Park where winners from the competion 
                                are announced.
                            </p>
                            <p>
                                More information about the festival can be 
                                found <a href="https://brewinthelou.com/">HERE</a>.
                            </p>
                            <p>
                                It is a great competition to benefit a great cause.  For more 
                                information about competition dates, check the <Link to="/calendar">Calendar</Link> page.
                            </p>
                        </div>
                    </div>
                </div>
            </center>
        </div>
    );
}

export default BIL;