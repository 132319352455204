import React, { useEffect, useState } from 'react';

const VirtuAleWinners = () => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetch('/assets/VirtuAle_Winners/VirtuAle_Winners.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch JSON data');
        }
        return response.json();
      })
      .then((data) => {
        const sortedFiles = data.sort((a, b) => b.year - a.year);
        setFiles(sortedFiles);
      })
      .catch((error) => {
        console.error('Error fetching file list:', error);
      });
  }, []);

  return (
    <div className="parent-container">
      <div className="file-list-wrapper">
        <h2>VirtuAle Winners</h2>
        <ul className="file-list">
          {files.map((file) => (
            <li key={file.filename} className="file-item">
              <a
                href={`/assets/VirtuAle_Winners/${file.filename}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.year}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};


export default VirtuAleWinners;
