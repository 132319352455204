import "./Home.css"

function HomeHeader() {
    return (
        <>
            <div  className="centered-offset">
                <img src='./assets/Brews.jpg' alt='Brews members' width='50%'/>
                <h2>Welcome to the St. Louis Brews Homebrew Club!</h2>
            </div>
        </>
    );
}

export default HomeHeader;