
function Footer() {
    return (
        <div >
        <center>
            <br />
            <hr width="50%" />
            <table className="footer-table">
                <tr>
                    <td  colSpan="3" className="footer-row">
                        Copyright 2025 St. Louis Brews | All Rights Reserved
                    </td>
                </tr>
                <tr >
                    <td className="footer-row">
                        <a href='https://www.facebook.com/groups/110584219030433'><img src="/assets/facebook.png" alt="Facebook" height = "20" width="20"/></a>
                        </td><td>
                        <a href='https://x.com/stlbrews'><img src="/assets/X.png" alt="X" height = "20" width="20"/></a>
                        </td><td>
                        <a href='https://www.instagram.com/stlbrews/'><img src="/assets/Instagram.png" alt="Instagram" height = "20" width="20"/></a>
                    </td>
                </tr>
            </table>
        </center>
        </div>
    );
}

export default Footer;