import { Link } from "react-router-dom";

function Benefits() {
    return (
        <div >
            <center><h3>Benefits of Membership</h3></center>
            <p>
                So what does joining the Brews get you? Well, in addition to all of the fun and comradery of our monthly meetings, you get:
            </p>
            <ul>
                <li>Monthly Newsletter - Features club news, calendar of events, beer style discussion, and a tech topic.</li>
                <li>Spring and Fall Picnic - We have club picnics in the spring and fall for our members. The club provides the main course and members bring side dishes.</li>
                <li>Friendship Brews - We randomly match up brewers to brew together on a defined style. The groups compete their beers against each other at the picnics.</li>
                <li>Holiday Banquet - Our HHHC banquet and annual homebrew competition. The club provides a nice main course (usually prime rib and other meats), and members bring side dishes. Competition awards are given out at the banquet, and the kids even get a visit from Santa!</li>
                <li>Jockey Box Usage - The club owns four jockey boxes, and members are free to use them whenever they choose.</li>
                <li>Firkin Usage - The club owns two ten-gallon firkins, and members are free to use them whenever they choose.</li>
                <li>Discounts - A number of area breweries, bars, and homebrew supply stores generously afford our members discounts. Please visit our <Link to='/resources/supporters'>Supporters</Link> page for a list.</li>
            </ul>
        </div>
    );
}

export default Benefits;