import React from 'react';
import VirtuAleWinners from '../../components/VirtuAle_Winners';

function VirtuAle() {
    return (
        <div >
            <center>
                <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                            <center>
                                <img alt='VirtuAle Logo' width='200' src='/assets/VirtuAle.jpg' />
                            </center>
                            <p>
                                The COVID pandemic brought everything to a halt, including beer competitions.
                                Microfest and HHHC were both cancelled that year, but we persevered.  In 
                                2020, the St. Louis Brews hosted a one-of-a-kind competition called VirtuAle.
                                VirtuAle entries were judged remotely via Zoom between two judges.
                                </p>
                                <p>
                                    Judging remotely presented many challenges, of course.  Entries consisted of 
                                    two bottles, and each judge got one of the bottles.  If the contents of the 
                                    two bottles were vastly different from each other (possibly due to an infected
                                    bottle), the judges had to work with each other to provide valuable feedback 
                                    while still giving a fair score.  And because both of the bottles were judge
                                    in the main round, there was no Best of Show.
                                </p>
                                <p>
                                    We did end up getting 125 entries that year, but lets hope we never have
                                    to judge a competition like that again!  The results are posted below.
                            </p>
                            <br />
                            <br />
                            <center>
                                <a href="https://stlbrews.brewingcompetitions.com/"><img alt='Register' width='200' src='/assets/register.jpg' /></a> 
                            </center>
                            <VirtuAleWinners />
                        </div>
                    </div>
                </div>
            </center>
        </div>
    );
}

export default VirtuAle;