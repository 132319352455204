import "./Join.css";
import React from 'react';

function Join() {
    return (
        <div >
            <center>
            <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                        <h1>Join or Renew!</h1>
                        <p>
                            Membership is currently $35.00 per calendar year. If are a new member and join after July 1, the membership fee is $17.50. 
                        </p>
                        <p>
                            When you become a member you receive a monthly newsletter, invitations to our two picnics, our National Homebrew Day celebration, 
                            and our end of year banquet, not to mention special events that come along such as brewery tours, meet & greets with brewing 
                            illuminati, beer tastings, etc.  
                        </p>
                        <p>
                        Want to give us a test drive before joining?  Come as a guest a few times!  <b>Membership in the St. Louis Brews and attendance 
                        at our meetings is restricted to those 21 years of age and older.</b>
                        </p>
                        <p>
                            Please click here to pay.  Note that the page lists the payment as a "donation".  This was the easiest to 
                            implement and I am working on a better solution.
                        </p>
                        <br />
                        <center>
                            <div>
                                <a 
                                    href="https://www.paypal.com/donate/?hosted_button_id=8X9W25XKMMLG8" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <img 
                                    src="/assets/PayPal.png" 
                                    alt="PayPal" 
                                    style={{ width: '200px', height: 'auto' }} 
                                    />
                                </a>
                            </div>
                            </center>
                        </div>
                    </div>
                </div>


            </center>
        </div>
    );
}

export default Join;