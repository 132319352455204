import React from 'react';
import { Link } from "react-router-dom";

const Education = () => {
  return (
    <>
            <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                        <h1>Education</h1>
                        <p>
                            One of the best ways to become a better brewer is to learn how to judge beer.
                            Judging teaches you how to really taste beer.  You learn what 
                            off-flavors can be in beer and how they get there.  And you learn the characteristics
                            of each of the BJCP styles. 
                        </p>
                        <p> 
                            There are now over 70 BJCP ranked beer judges in the St. Louis area, including over 
                            20 with mead certification and 11 with cider certification.  
                            Many of these judges are current or past members of the Brews.  
                        </p>
                        <p>
                            Our club was built with a goal of providing beer education to its members.  As part of this,
                            we hold BJCP training classes in preparation for the BJCP exams.  We provide training
                            for not just the BJCP Beer Exam, but also for Mead and Cider certifications.  We also
                            administer the exams, so you won't have to worry about travelling to take the exam.
                        </p>
                        <p>
                            Our training sessions are approximately 11 weeks long, spread out over approximately four months.
                            We meet once a week, with each session concentrating on 4 or 5 styles and several off-flavors.
                            Students are responsible for bringing classic examples of a few of the styles over the 
                            course and there is a small charge to cover incidentals.  But this is a great way to increase 
                            your beer knowledge.
                        </p>
                        <p>
                            If you are interested in taking the exam or the training classes, please 
                            fill out this <Link to='/contact-bjcpeducation'>form</Link> and we will keep you 
                            informed of our next session and exam date.
                        </p>
                        <p>
                            Another great way to learn the judging process
                            and become more knowledge about styles and off-flavors is to help steward one of our competitions.  Many times, you will
                            get to listen to the judges comments about a beer and then be able to taste the beer for yourself.  Why not
                            give it a try?
                        </p>
                        </div>
                    </div>
                </div>
    </>
  );
};

export default Education;
