import React from 'react';
import logo from './BrewsLogo.jpg'; // Importing the image
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div>
      <center><Link to='/'><img src={logo} alt="Logo" height="156" width="180" /></Link></center>
    </div>
  );
};

export default Logo;