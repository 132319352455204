import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./ContactUs.css";

const ContactUs = ({ defaultRecipient = "officers" }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    recipient: defaultRecipient, // Set default recipient from props
  });
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    // Update the recipient if the defaultRecipient prop changes
    setFormData((prevData) => ({ ...prevData, recipient: defaultRecipient }));
  }, [defaultRecipient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailAddresses = {
      president: "president@stlbrews.org",
      vicepresident: "vicepresident@stlbrews.org",
      treasurer: "treasurer@stlbrews.org",
      secretary: "secretary@stlbrews.org",
      brewmeister: "brewmeister@stlbrews.org",
      beermikester: "beermikester@yahoo.com",
      webmeister: "webmeister@stlbrews.org",
      jockeyboxes: "jockeyboxes@stlbrews.org",
      calendar: "calendar@stlbrews.org",
      officers: "officers@stlbrews.org",
      bjcpeducation: "bjcpeducation@stlbrews.org",
    };

    const recipientEmail = emailAddresses[formData.recipient];
    const subject = "Message from stlbrews.org website";

    emailjs
      .send(
        "service_qcz3l3h", // Replace with your EmailJS Service ID
        "template_v34imqf", // Replace with your EmailJS Template ID
        {
          from_name: formData.name,
          reply_to: formData.email,
          message: formData.message,
          recipient_email: recipientEmail,
          subject: subject,
        },
        "P2eAI93KlWlPLiKSM" // Replace with your EmailJS Public Key
      )
      .then(
        (response) => {
          console.log("Email sent successfully!", response.status, response.text);
          setStatusMessage("Your message has been sent!");
          setFormData({
            name: "",
            email: "",
            message: "",
            recipient: defaultRecipient, // Reset to default recipient
          });
        },
        (err) => {
          console.error("Failed to send email:", err);
          setStatusMessage("Failed to send your message. Please try again later.");
        }
      );
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label htmlFor="recipient">Send To:</label>
          <select
            id="recipient"
            name="recipient"
            value={formData.recipient}
            onChange={handleChange}
          >
            <option value="president">President</option>
            <option value="vicepresident">Vice President</option>
            <option value="treasurer">Treasurer</option>
            <option value="secretary">Secretary</option>
            <option value="brewmeister">Brewmeister</option>
            <option value="jockeyboxes">Jockey Boxes</option>
            <option value="webmeister">Webmeister</option>
            <option value="calendar">Calendar</option>
            <option value="officers">Officers</option>
            <option value="bjcpeducation">BJCP Education</option>
            <option value="beermikester">Mike</option>
          </select>
        </div>

        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <button type="submit">Send Message</button>
      </form>

      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default ContactUs;
