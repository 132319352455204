import React from 'react';
import { Link } from "react-router-dom";
import MicrofestWinners from '../../components/Microfest_Winners';

function Microfest() {
    return (
        <div >
            <center>
                <div className="master-table">
                    <div className="master-row">
                        <div className="master-column master-section">
                            <center>
                                <img alt='Microfest Logo' width='200' src='/assets/Microfest.jpg' />
                            </center>
                            <p>
                                The St. Louis Brews have sponsored the Micorofest Homebrew Competition since 
                                2000, which is associated with the Microfest beer festival and benefits the Lift 
                                For Life Gym.  The festival is usually held in May, and we try to time the 
                                competition to be completed a few weeks earlier so that winners can be announced
                                in their booklet given away at the festival. 
                            </p>
                            <p>
                                More information about the festival, and the Lift For Life Gym, can be 
                                found <a href="https://stlmicrofest.org/">HERE</a>.
                            </p>
                            <p>
                                The competition has grown so much over the years that we now have a cap of 250 
                                entries.  It is a great competition to benefit a great cause.  For more 
                                information about competition dates, check the <Link to="/calendar">Calendar</Link> page.
                            </p>
                            <h3>Registration Site</h3>
                            <p>
                                The registration site for entering or signing up as a judge or steward is below.  Registration for the competition typically
                                opens about four weeks before the competition.  Please check the site for more details.
                                <br />
                                <br />
                                <center>
                                    <a href="https://stlbrews.brewingcompetitions.com/"><img alt='Register' width='200' src='/assets/register.jpg' /></a> 
                                </center>
                            </p>
                            <MicrofestWinners />
                        </div>
                    </div>
                </div>
            </center>
        </div>
    );
}

export default Microfest;