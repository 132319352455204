import React from 'react';

function Presentations() {
    return (
        <div >
            <div className="master-table">
                <div className="master-row">
                    <div className="master-column master-section">
                        <h1>Presentations</h1>
                        <p>
                            <a
                                href="/assets/IntroToJudging.pdf" target="_blank" rel="noopener noreferrer">Introduction To Judging</a>.
                        </p>
                        <p>
                            <a
                                href="/assets/IntroToHomebrewing.pdf" target="_blank" rel="noopener noreferrer">Introduction To Homebrewing</a>.
                        </p>
                    </div>
                </div>
            </div>
       </div>
    );
}

export default Presentations;