import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Logo from "./components/Logo/Logo.js";
import Footer from "./components/Footer.js";
import './styles/index.css';
import HHHC from "./pages/Competitions/HHHC.js";
import Home from "./pages/Home/Home.js";
import Calendar from "./pages/Calendar/Calendar.js";
import BeerStyles from "./pages/MonthlyStyles/BeerStyles.js";
import Join from "./pages/Join/Join.js";
import ContactUs from "./pages/ContactUs/ContactUs.js";
import Officers from "./pages/Officers/Officers.js";
import ByLaws from "./pages/By-Laws/By-Laws.js";
import AboutUs from "./pages/AboutUs/AboutUs.js";
import MeadMe from "./pages/Competitions/MeadMe.js";
import Microfest from "./pages/Competitions/Microfest.js";
import VirtuAle from "./pages/Competitions/VirtuAle.js";
import BIL from "./pages/Competitions/BIL.js";
import BrewNote from "./pages/BrewNote/BrewNote.js";
import Supporters from "./pages/Supporters/Supporters.js";
import Education from "./pages/Education/Education.js";
import Presentations from "./pages/Presentations/Presentations.js";

const App = () => {
  return (
    <Router>
      <div className="master-table">
          <div className="master-row">
              <div className="master-column master-section">
                <Logo />
                <Navbar />
              </div>
          </div>
      </div>

      <br />
      <div className="content">
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/club-info" element={<AboutUs />} />
              <Route path="/club-info/about" element={<AboutUs />} />
              <Route path="/club-info/officers" element={<Officers />} />
              <Route path="/club-info/styles" element={<BeerStyles />} />
              <Route path="/club-info/join" element={<Join />} />
              <Route path="/club-info/bjcpeducation" element={<Education />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/contact" element={<ContactUs defaultRecipient="officers" />} />
              <Route path="/contact-brewmeister" element={<ContactUs defaultRecipient="brewmeister" />} />
              <Route path="/contact-president" element={<ContactUs defaultRecipient="president" />} />
              <Route path="/contact-vicepresident" element={<ContactUs defaultRecipient="vicepresident" />} />
              <Route path="/contact-treasurer" element={<ContactUs defaultRecipient="treasurer" />} />
              <Route path="/contact-secretary" element={<ContactUs defaultRecipient="secretary" />} />
              <Route path="/contact-webmeister" element={<ContactUs defaultRecipient="webmeister" />} />
              <Route path="/contact-jockeyboxes" element={<ContactUs defaultRecipient="jockeyboxes" />} />
              <Route path="/contact-calendar" element={<ContactUs defaultRecipient="calendar" />} />
              <Route path="/contact-bjcpeducation" element={<ContactUs defaultRecipient="bjcpeducation" />} />
              <Route path="/competitions" element={<HHHC />} />
              <Route path="/competitions/hhhc" element={<HHHC />} />
              <Route path="/competitions/microfest" element={<Microfest />} />
              <Route path="/competitions/virtuale" element={<VirtuAle />} />
              <Route path="/competitions/meadme" element={<MeadMe />} />
              <Route path="/competitions/bil" element={<BIL />} />
              <Route path="/resources" element={<BrewNote />} />
              <Route path="/resources/brewnotes" element={<BrewNote />} />
              <Route path="/resources/bylaws" element={<ByLaws />} />
              <Route path="/resources/supporters" element={<Supporters />} />
              <Route path="/resources/presentations" element={<Presentations />} />
          </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;